























































































import Vue from "vue";
import { mapState, mapActions } from "pinia";
import { useContentStore } from "@/store/contentStore";
import ContentItemSummary from "@/components/contentItems/ContentItemSummary.vue";
import { ContentItemSummaryResult, ContentItemType } from "@/api/DoceoApi";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "Discover",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  components: { ContentItemSummary, DoceoIcon },
  data: () => ({
    isLoading: false,
    isLoadingTrending: false,
    DOCEO_ICONS,
    isFetchingMoreSummaries: false,
    stopLoading: false,
    selectedContentTypes: [] as ContentItemType[],
    ContentItemType,
    contentTypes: [
      { text: "Article", value: ContentItemType.Article },
      { text: "Drug", value: ContentItemType.DrugBrandGroup },
      { text: "Decision", value: ContentItemType.Decision },
      { text: "Trial", value: ContentItemType.Trial },
      { text: "Folio", value: ContentItemType.Folio },
      { text: "User Article", value: ContentItemType.UserArticle },
      { text: "User External Link", value: ContentItemType.UserExternalLink },
    ],
  }),
  /**
   * Before the component is destroyed, we need to reset the onscroll method to do nothing
   */
  beforeDestroy() {
    this.stopLoading = true;
    window.onscroll = async () => {};
  },
  /**
   * When the component is mounted, we first check if there have been any summaries loaded,
   * if so:
   *  - Get the last scroll position and scroll the page
   * otherwise:
   *  - Reset the last scroll position (As an extra precaution)
   *  - Fetch the latest summaries
   * finally:
   *  - Load more summaries until the page is full
   *  - Attach the scroll event that will fetch summaries as the user scrolls
   */
  async mounted() {
    this.selectedContentTypes = this.filters;
    if (this.summaries.length == 0) {
      this.isLoading = true;
      this.fetchFollowed(this.count);
      this.loadTrending();
      await this.fetchSummaries();
      this.isLoading = false;

      // Check if we need to load additional content (As the first fetch is smaller and may not fill the screen but gets content displayed quicker)
      await this.checkScroll();
    }

    window.onscroll = this.checkScroll;
  },

  methods: {
    ...mapActions(useContentStore, ["fetchSummaries", "setSummaryFilter", "fetchTrending", "fetchFollowed", "resetSummaries"]),
    async loadTrending() {
      this.isLoadingTrending = true;
      await this.fetchTrending(this.count);
      this.isLoadingTrending = false;
    },
    async fetchContent(isBlur: boolean) {
      this.resetSummaries();
      this.isLoading = true;
      Vue.nextTick(async () => {
        this.isFetchingMoreSummaries = true;
        await this.setSummaryFilter(this.selectedContentTypes);
        this.isFetchingMoreSummaries = false;
        this.isLoading = false;
        window.onscroll = this.checkScroll;
        // Check if we need to load additional content (As the first fetch is smaller and may not fill the screen but gets content displayed quicker)
        await this.checkScroll();
      });
    },
    /**
     * When the user scrolls to the bottom of the page, load another set of summaries.
     */
    async checkScroll() {
      let loadedNewContent = false;

      if (!this.isFetchingMoreSummaries) {
        let currentPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight;
        let documentHeight = document.documentElement.offsetHeight;
        let offset = 3000; // How many pixels before the end of the page, we want to fetch the next set of summaries

        if (currentPosition >= documentHeight - offset || this.summaries.length <= 30) {
          this.isFetchingMoreSummaries = true;
          let oldSummaryCount = this.summaries.length;
          await this.fetchSummaries();
          this.isFetchingMoreSummaries = false;
          loadedNewContent = this.summaries.length > oldSummaryCount;

          if (!loadedNewContent) {
            window.onscroll = null;
          }
        }
      }

      return loadedNewContent;
    },

    async refreshContent() {
      await this.fetchSummaries();
    },
  },
  computed: {
    ...mapState(useContentStore, ["summaries", "newContentAdded", "trending", "followed", "filters"]),
    newestSummaries(): ContentItemSummaryResult[] {
      return this.summaries;
    },
    count(): number {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": // < 600px
          return 1;
        case "sm": // 600px > < 960px
          return 2;
        case "md": // 960px > < 1264px
          return 3;
        case "lg": // 1264px > < 1904px
          return 4;
        case "xl": //	> 1904px
          return 4;
        default:
          return 1;
      }
    },
  },
  watch: {
    async count() {
      this.fetchTrending(this.count);
      this.fetchFollowed(this.count);
    },
  },
});
